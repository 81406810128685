html {
  margin:0;
  padding:0;
}

body {
    font-family: 'Rubik', 'Poppins', Helvetica, 'sans-serif';
    background-color: #f2f3f8;
    color: #5E6278;
    margin:0;
    padding:1rem;
  }

.auth-brand {
  text-align: center;
}
.auth-brand img {
  max-width: 20%;
  min-width: 8rem;
  margin-bottom: 1rem;
}
.auth-footer, .footer {
  text-align: center;
  font-size: var(--amplify-font-sizes-xs) ;
  padding-top: 1rem;
  
}

.auth-footer .amplify-text, .footer .amplify-text {
  color: var(--amplify-colors-neutral-60);
}

[data-amplify-authenticator], .accounts-container {
  /* --amplify-colors-background-primary: var(--amplify-colors-neutral-90);
  --amplify-colors-background-secondary: var(--amplify-colors-neutral-100); */
  --amplify-colors-brand-primary-10: var(--amplify-colors-red-100);
  --amplify-colors-brand-primary-80: var(--amplify-colors-red-40);
  --amplify-colors-brand-primary-90: var(--amplify-colors-red-20);
  --amplify-colors-brand-primary-100: var(--amplify-colors-red-10);
  --amplify-colors-font-interactive: var(--amplify-colors-red-100);
  --amplify-components-tabs-item-active-color: var(--amplify-colors-red-60);
  --amplify-components-tabs-item-focus-color: var(--amplify-colors-red-60);
  --amplify-components-tabs-item-hover-color: var(--amplify-colors-red-60);
  --amplify-components-tabs-item-active-border-color: var(--amplify-colors-red-60);

  --amplify-components-button-primary-hover-background-color: var(--amplify-colors-red-80);
  --amplify-components-button-primary-active-background-color: var(--amplify-colors-red-80);
  --amplify-components-button-primary-focus-background-color: var(--amplify-colors-red-80);
  --amplify-components-button-primary-focus-border-color: var(--amplify-colors-red-80);
  --amplify-components-button-primary-active-border-color: var(--amplify-colors-red-80);
  --amplify-components-button-primary-background-color: var(--amplify-colors-red-60);

  --amplify-components-button-link-hover-background-color: var(--amplify-colors-red-20);
  --amplify-components-button-link-hover-color: var(--amplify-colors-red-80);
  --amplify-components-button-link-color: var(--amplify-colors-red-80);
  --amplify-components-button-link-active-background-color: var(--amplify-colors-red-80);
   --amplify-components-button-link-active-border-color: var(--amplify-colors-red-80);
  --amplify-components-button-link-active-color: var(--amplify-colors-red-10);
  --amplify-components-button-link-focus-color: var(--amplify-colors-red-80);
  --amplify-components-button-link-focus-border-color: var(--amplify-colors-red-80) !important;
  --amplify-components-button-link-focus-background-color: var(--amplify-colors-red-20);
  --amplify-components-button-link-visited-background-color: var(--amplify-colors-red-80);

  --amplify-components-button-primary-focus-box-shadow: 0px 0px 0px 1px var(--amplify-colors-red-80);
  --amplify-components-button-link-focus-box-shadow: 0px 0px 0px 1px var(--amplify-colors-red-80);

  --amplify-components-authenticator-router-border-width: 0px;

  --amplify-components-button-hover-background-color: var(--amplify-colors-red-10);
  --amplify-components-button-hover-border-color: var(--amplify-colors-red-40);
  --amplify-components-button-active-background-color: var(--amplify-colors-red-10);
   --amplify-components-button-active-border-color: var(--amplify-colors-red-40);
   --amplify-components-button-focus-border-color: var(--amplify-colors-red-80);
   --amplify-components-button-focus-box-shadow: 0px 0px 0px 1px var(--amplify-colors-red-80);

    --amplify-components-passwordfield-button-hover-background-color: var(--amplify-colors-red-10);
  --amplify-components-passwordfield-button-hover-border-color: var(--amplify-colors-red-40);
  --amplify-components-passwordfield-button-active-background-color: var(--amplify-colors-red-10);
   --amplify-components-passwordfield-button-active-border-color: var(--amplify-colors-red-40);
   --amplify-components-passwordfield-button-focus-border-color: var(--amplify-colors-red-80);
   --amplify-components-passwordfield-button-focus-box-shadow: 0px 0px 0px 1px var(--amplify-colors-red-80);
}


.federated-sign-in-container {
 flex-direction: row !important;
 margin-bottom: 1rem;
 justify-content: center;
 align-items: stretch;
}

.federated-sign-in-container span,.federated-sign-in-container hr  {
  display: none;
}

.federated-sign-in-container .amplify-button {
  flex: 1;
}

.federated-sign-in-container .amplify-icon {
  height: 2rem;
}

/* .card-client-wrapper .amplify-flex {
  justify-content: center;
} */

.amplify-card {
  text-align: center;
}

.accounts-container {
      display: grid;
  }

@media (min-width: 30rem) {
  .accounts-container .accounts-wrapper {
      width: 35rem;
    /* width: var(--amplify-components-authenticator-container-width-max); */
  }
}

.accounts-container .accounts-wrapper {
place-self: center;
}

.accounts-container .accounts-tabs{
    
    background-color: var(--amplify-components-authenticator-router-background-color);
    border-color: var(--amplify-components-authenticator-router-border-color);
    border-style: var(--amplify-components-authenticator-router-border-style);
    border-width: var(--amplify-components-authenticator-router-border-width);
    box-shadow: var(--amplify-components-authenticator-router-box-shadow);
    
}

.accounts-container .accounts-tabs [data-state=inactive] {
    background-color: #fafafa;
    background-color: var(--amplify-components-authenticator-state-inactive-background-color);
}

.accounts-tab {
  /* padding: var(--amplify-components-authenticator-form-padding);*/
  padding: 1rem;
} 

.accounts-tab .amplify-button {
  /* margin-top: 1rem; */
}

.accounts-container .brand {
  place-self: center;
  text-align: center;
}
.accounts-container .brand img {
  max-width: 20%;
  min-width: 8rem;
  margin-bottom: 1rem;  
}

.footer-signout {
  margin-top:  1rem;
  text-align: center;
}

.amplify-card--elevated {
  --amplify-components-card-elevated-background-color: var(--amplify-colors-neutral-20);
    background-color: var(--amplify-components-card-elevated-background-color);
    /* border-radius: var(--amplify-components-card-elevated-border-radius);
    border-width: var(--amplify-components-card-elevated-border-width);
    border-style: var(--amplify-components-card-elevated-border-style);
    border-color: var(--amplify-components-card-elevated-border-color);
    box-shadow: var(--amplify-components-card-elevated-box-shadow); */
}

.card-hidden {
  display: none;
}

.twofactor fieldset {
  margin-bottom: 1rem;
}